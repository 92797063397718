import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Card } from 'react-bootstrap';
import CardContent from '@mui/material/CardContent';
import { Bar } from 'react-chartjs-2';
import Api from '../../../../services/Api';
import dateFormat from 'dateformat';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const options = {
  responsive: true,
  scales: {
    y: {},
    x: {},
  },
  hoverRadius: 5,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },
  plugins: {
    tooltip: {
      enabled: true
    },
    title: {
      display: false,
      text: '',
    }
  }
}

const plugins = [{
  afterDraw: function (chart) {
    if (chart.data.datasets.length > 0) {
      if (chart.data.datasets[0].data.every(item => item === 0)) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Nenhuma Informação Disponível",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  }
}]

const Graphics = props => {
  const { equipaments, setIsLoading } = props;

  const [volumeDias, setVolumeDias] = useState({
    labels: [],
    datasets: []
  });

  const [volumeMes, setVolumeMes] = useState({
    labels: [],
    datasets: []
  });

  const [sumDays, setSumDays] = useState(0);
  const [sumMonth, setSumMonth] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true)
        var { data } = await Api.post('/measurements/volume', { "filter": equipaments });
      } catch (err) {
        setVolumeDias([])
        setIsLoading(false)
      }

      var labels = data.day.map(i => {
        var date = new Date(0);
        date.setHours(i.hour - 3);
        var timeString = date.toISOString().substring(11, 16);
        return timeString;
      });

      var quantity = data.day.map(i => i.count)
      var sum = quantity.reduce((a, b) => a + b, 0)
      setSumDays(sum)

      setVolumeDias({
        labels,
        datasets: [
          {
            label: `Medidas hora`.toUpperCase(),
            data: data.day.map(i => i.count),
            borderColor: 'rgba(27, 93, 67, 0.8)',
            fill: true,
            borderWidth: 2,
            pointRadius: 2,
            datalabels: {
              labels: {
                title: {
                  color: "grey",
                }
              },
              display: 'auto',
              align: 'center',
              font: {
                weight: 500,
                size: 13,
              }
            },
          }
        ],
      });

      labels = data.month.map(i => dateFormat(i.day, "UTC:dd"))
      quantity = data.month.map(i => i.count)
      sum = quantity.reduce((a, b) => a + b, 0)
      setSumMonth(sum)

      setVolumeMes({
        labels,
        datasets: [
          {
            label: `Medidas dia`.toUpperCase(),
            data: data.month.map(i => i.count),
            borderColor: 'rgba(27, 93, 67, 0.8)',
            fill: true,
            borderWidth: 2,
            pointRadius: 2,
            datalabels: {
              labels: {
                title: {
                  color: "grey",
                }
              },
              display: 'auto',
              align: 'center',
              font: {
                weight: 500,
                size: 13,
              }
            },
          }
        ],
      })
      setIsLoading(false)
    }
    getData();
  }, []);

  return (
    <div style={{ "marginTop": 20 }}>
      <div style={{ "textAlign": 'center', "marginBottom": 5 }}>
        <h4 style={{ "color": 'white', "margin": 5 }}>{`Volume de medições`.toUpperCase()}</h4>
      </div>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div style={{ "textAlign": 'center' }}>
                  <p>{`TOTAL DO DIA ${sumDays}`}</p>
                  <Bar
                    height={90}
                    options={options}
                    data={volumeDias}
                    plugins={[plugins, ChartDataLabels]}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ "textAlign": 'center' }}>
                  <p>{`TOTAL DO MÊS ${sumMonth}`}</p>
                  <Bar
                    height={90}
                    options={options}
                    data={volumeMes}
                    plugins={[plugins, ChartDataLabels]}
                  />
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  )
}
export default Graphics;
