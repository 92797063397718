import React from 'react'
import NavBar from '../NavBar/NavBar'

const Header = () => {

  return (
    <header style={{"borderBottom": "solid 1px rgb(1, 84, 49)"}}>
      <NavBar />
    </header>
  )
}
export default Header