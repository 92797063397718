import React, { useEffect, useState } from 'react';
import Header from '../../../../components/Header/Header';
import ButtonBack from '../../../../components/ButtonBack/ButtonBack';
import Grafico from './components/Grafico';
import { BsSave2 } from "react-icons/bs";
import { Card, Col, Row, Button, Container } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Loader from '../../../../components/Loader/Loader';
import Api from '../../../../services/Api';

const MedicoesVisualizar = () => {
  const [instrument] = useState(JSON.parse(localStorage.getItem('instruments')))
  const [dataInicioEntrada, setDataInicioEntrada] = useState()
  const [dataFimEntrada, setDataFimEntrada] = useState()
  const [dataInicioSaida, setDataInicioSaida] = useState()
  const [dataFimSaida, setDataFimSaida] = useState()
  const [measurementsEntrada, setMeasurementsEntrada] = useState([])
  const [measurementsSaida, setMeasurementsSaida] = useState([])
  const [param] = useState(50)
  const [countNsEntrada, setCountNsEntrada] = useState({})
  const [countNsSaida, setCountNsSaida] = useState({})
  const [bool, setBool] = useState(true)

  const nsEntrada = instrument.equipamentos.entrada.ns;
  const nsSaida = instrument.equipamentos.saida.ns;

  const handleEquipaments = async () => {
    setBool(true)
    var data = [];
    let listRevertida = [];
    try {
      if (nsEntrada && nsEntrada.length === 10) {
        data = await Api.get(`/measurements/byEquip/lastn/${nsEntrada}/${param}`);
        listRevertida = [...data.data].reverse()
        setMeasurementsEntrada(listRevertida)
      }
    } catch (error) {
      console.log(error);
    }

    try {
      if (nsSaida && nsSaida.length === 10) {
        data = await Api.get(`/measurements/byEquip/lastn/${nsSaida}/${param}`);
        listRevertida = [...data.data].reverse()
        setMeasurementsSaida(listRevertida)
      }
    } catch (error) {
      console.log(error);
    }
    setBool(false)
  }

  const handleCount = async () => {
    var data = ''
    try {
      if (nsEntrada && nsEntrada.length === 10) {
        data = await Api.get(`/measurements/count/${nsEntrada}`);
        setCountNsEntrada(data.data)
      }
    } catch (error) {
      console.log(error);
    }
    try {
      if (nsSaida && nsSaida.length === 10) {
        data = await Api.get(`/measurements/count/${nsSaida}`);
        setCountNsSaida(data.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDataEntrata = async () => {
    if (dataInicioEntrada && dataFimEntrada) {
      window.open(`${process.env.REACT_APP_API_MONITORA}/logs/filter/${nsEntrada}?inicio=${dataInicioEntrada}&fim=${dataFimEntrada}`, "_blank")
    } else {
      alert("Insira data e horário do início e fim")
    }
  }

  const handleDataSaida = async () => {
    if (dataInicioSaida && dataFimSaida) {
      window.open(`${process.env.REACT_APP_API_MONITORA}/logs/filter/${nsSaida}?inicio=${dataInicioSaida}&fim=${dataFimSaida}`, "_blank")
    } else {
      alert("Insira data e horário do início e fim")
    }
  }

  const getReportEntrada = async () => {
    if (instrument.equipamentos.entrada.ns) {
      window.open(`${process.env.REACT_APP_API_MONITORA}/logs/user/${nsEntrada}`, "_blank")
    } else {
      alert("Algo deu errado.")
    }
  }

  const getReportSaida = async () => {
    if (instrument.equipamentos.saida.ns) {
      window.open(`${process.env.REACT_APP_API_MONITORA}/logs/user/${nsSaida}`, "_blank")
    } else {
      alert("Algo deu errado.")
    }
  }

  useEffect(() => {
    if (instrument) {
      handleEquipaments()
      handleCount()
    }
  }, [])

  return (
    <>
      <Header />
      {bool && <Loader />}
      <>
        {measurementsEntrada ?
          <Card className='mb-3 mt-3 pt-3'>
            <h4 className='p-2 text-center'> {`Equipamento de entrada · ${instrument.equipamentos.entrada.ns} · Curva ${instrument.curva}`.toUpperCase()} </h4>
            <Row className='p-2 col-sm-12'>
              <Col className='col-sm-7 d-flex'>
                <TextField
                  style={{ "paddingRight": "5px", "height": "35px !important" }}
                  className={"inputdata col-sm-3"}
                  autoComplete="off"
                  id="countNsEntrada  ff"
                  variant="outlined"
                  type="TextField"
                  size="small"
                  label={countNsEntrada.n ? "Total de medições entrada" : "Sem medições"}
                  value={countNsEntrada.n ? countNsEntrada.n : 0}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
                <TextField
                  style={{ "marginRight": "5px" }}
                  className="inputdata col-sm-4"
                  autoComplete="off"
                  id="numero-inicio-entrada"
                  label="Data início entrada"
                  variant="outlined"
                  type="datetime-local"
                  onChange={(event) => {
                    let dataInicioEntrada = (event.target.value)
                    setDataInicioEntrada(dataInicioEntrada)
                  }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataInicioEntrada}
                />
                <TextField
                  style={{ "marginRight": "5px" }}
                  className="inputdata col-sm-4"
                  autoComplete="off"
                  id="numero-fim-entrada"
                  label="Data fim entrada"
                  variant="outlined"
                  type="datetime-local"
                  onChange={(event) => {
                    let dataFimEntrada = (event.target.value)
                    setDataFimEntrada(dataFimEntrada)
                  }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataFimEntrada}
                />
              </Col>
              <Col className='col-sm-5 d-flex'>
                <Button className='px-3 col-sm-6' variant="success" size='sm' onClick={() => { handleDataEntrata() }} disabled={false} >
                  <BsSave2 /> Baixar Relatório
                </Button>
                <Button className='mx-3 px-3 col-sm-6' variant="success" size='sm' onClick={() => { getReportEntrada() }} >
                  <BsSave2 /> Baixar Relatório Completo
                </Button>
              </Col>
            </Row>
            <div className='mt-2'>
              <Grafico equipamento={measurementsEntrada} equip={instrument.equipamentos.entrada.ns} instruments={instrument} posicao={"Entrada"} />
            </div>
          </Card>
          : null}
        {measurementsSaida.length ?
          <Card className='mb-3 pt-3'>
            <h4 className='p-2 text-center'> {`Equipamento de saída · ${instrument.equipamentos.saida.ns} · Curva ${instrument.curva}`.toUpperCase()} </h4>
            <Row className='p-2 col-sm-12'>
              <Col className='col-sm-7 d-flex'>
                <TextField
                  style={{ "paddingRight": "5px" }}
                  className={"inputdata col-sm-3"}
                  autoComplete="off"
                  id="countNsSaida"
                  variant="outlined"
                  type="TextField"
                  size="small"
                  label={countNsSaida.n ? "Total de medições saída" : "Sem medições"}
                  value={countNsSaida.n ? countNsSaida.n : 0}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
                <TextField
                  style={{ "marginRight": "5px" }}
                  className="inputdata col-sm-4"
                  autoComplete="off"
                  id="numero-inicio-saida"
                  label="Data início saida"
                  variant="outlined"
                  type="datetime-local"
                  onChange={(event) => {
                    let dataInicioSaida = (event.target.value)
                    setDataInicioSaida(dataInicioSaida)
                  }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataInicioSaida}
                />
                <TextField
                  style={{ "marginRight": "5px" }}
                  className="inputdata col-sm-4"
                  autoComplete="off"
                  id="numero-fim-saida"
                  label="Data fim saída"
                  variant="outlined"
                  type="datetime-local"
                  onChange={(event) => {
                    let dataFimSaida = (event.target.value)
                    setDataFimSaida(dataFimSaida)
                  }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataFimSaida}
                />
              </Col>
              <Col className='col-sm-5 d-flex'>
                <Button className='px-3 col-sm-6' variant="success" size='sm' onClick={() => { handleDataSaida() }} >
                  <BsSave2 /> Baixar Relatório
                </Button>
                <Button className='mx-3 px-3 col-sm-6' variant="success" size='sm' onClick={() => { getReportSaida() }} >
                  <BsSave2 /> Baixar Relatório Completo
                </Button>
              </Col>
            </Row>
            <div className='mt-2'>
              {measurementsSaida && instrument &&
                <Grafico equipamento={measurementsSaida} equip={instrument.equipamentos.saida.ns} instruments={instrument} posicao={"Saída"} />
              }
            </div>
          </Card>
          : null}
        <div className='mt-3 mb-3'>
          <ButtonBack />
        </div>
      </>
    </>
  )
}
export default MedicoesVisualizar