export const checkAuth = () => {
  let userData = localStorage.getItem('empresa');
  if (userData === null) {
    return false;
  }
  let user = JSON.parse(userData);
  let lastLogDate = user.date;

  const date1 = new Date();
  const date2 = new Date(lastLogDate);
  const diffTime = Math.abs(date2 - date1);

  return diffTime > 43200 * 1000 ? false : true
}

export const getToken = () =>
  JSON.parse(localStorage.getItem('empresa')) != null
    ? JSON.parse(localStorage.getItem('empresa')).token
    : null;

/* export const logout = () => {
  localStorage.clear() === null ? false : true;
} */
