import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import dateFormat from "dateformat";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Card, Container, Row, Col } from 'react-bootstrap';

const Grafico = (props) => {
  const { equipamento, instruments } = props

  const [humidity, setHumidity] = useState({
    labels: [],
    datasets: []
  })
  const [optionshumidity, setOptionshumidity] = useState(null)
  const [peso, setPeso] = useState({
    labels: [],
    datasets: []
  })
  const [optionspeso, setOptionspeso] = useState(null)
  const [temperatura, setTemperatura] = useState({
    labels: [],
    datasets: []
  })
  const [optionstemperatura, setOptionstemperatura] = useState(null)


  function updateInfoGraphics() {
    let colorB = 'rgba(27, 93, 67, 0.8)'
    let backgroundGraphics = 'rgba(41, 140, 101, 0.6)'

    let labels = equipamento.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM"));
    const humidityLabel = {
      labels,
      datasets: [{
        labels,
        label: 'UMIDADE',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.humidity).toFixed(1))),
        borderColor: colorB,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              color: "grey" //'#286632',
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 600,
            size: 12,
          }
        },
      }
      ]
    }
    setHumidity(humidityLabel)

    let number = equipamento.map((equipamento) => (parseFloat(equipamento.humidity).toFixed(1)))
    let maxValue = Math.max(...number)
    let minValue = Math.min(...number)

    const optionsHumididy = {
      scales: {
        y: {
          min: minValue - 2,
          max: maxValue + 3,
          stepSize: 5,
        },
        x: {},
      }
    };
    setOptionshumidity(optionsHumididy)

    //----------------------------------------------------------------

    labels = equipamento.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM"));
    const pesoLabel = {
      labels,
      datasets: [{
        labels,
        label: 'PESO EM GRAMAS',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.peso).toFixed(0))),
        borderColor: colorB,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              color: "grey" //'#286632',
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 600,
            size: 12,
          }
        },
      },
      ]
    }
    setPeso(pesoLabel)

    number = equipamento.map((equipamento) => (parseFloat(equipamento.peso).toFixed(1)))
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionspeso = {
      scales: {
        y: {
          min: minValue - 2,
          max: maxValue + 3,
          stepSize: 5,
        },
        x: {},
      }
    };
    setOptionspeso(optionspeso)

    //----------------------------------------------------------------

    labels = equipamento.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM"));
    const temperaturaLabel = {
      labels,
      datasets: [{
        labels,
        label: 'TEMPERATURA',
        data: equipamento.map((equipamento) => (parseFloat(equipamento.temperature).toFixed(1))),
        borderColor: colorB,
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              color: "grey" //'#286632',
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 600,
            size: 12,
          }
        },
      },
      ]
    }
    setTemperatura(temperaturaLabel)

    number = equipamento.map((equipamento) => (parseFloat(equipamento.temperature).toFixed(1)))
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionsPeso = {
      scales: {
        y: {
          min: minValue - 2,
          max: maxValue + 3,
          stepSize: 5,
        },
        x: {},
      }
    };
    setOptionstemperatura(optionsPeso)
  }

  useEffect(() => {
    updateInfoGraphics()
  }, [equipamento])

  const plugins = {
    afterDraw: function (chart) {
      if (chart.data.datasets.length > 0) {
        if (chart.data.datasets[0].data.length === 0) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          var text = "Nenhuma Informação Disponível",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }
    },
  }

  return (
    <Container fluid>
      <Row>
        <h5 className='card-h6-barchart' style={{ "marginBottom": "0px" }}> {instruments.name} </h5>
        <Col xl={6}>
          {humidity && optionshumidity &&
            <Line options={optionshumidity} data={humidity} plugins={[plugins, ChartDataLabels]} height={150} />
          }
        </Col>
        <Col xl={6}>
          {peso && optionshumidity &&
            <Line options={optionspeso} data={peso} plugins={[plugins, ChartDataLabels]} height={150} />
          }
        </Col>
      </Row>
    </Container>
  )
}
export default Grafico