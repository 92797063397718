import React, { useEffect, useState } from 'react'
import Api from '../../services/Api';
import Header from '../../components/Header/Header';
import EmpresaForm from './components/EmpresaForm'
import Loader from '../../components/Loader/Loader';
import { Container } from 'react-bootstrap';

const EditarEmpresa = () => {
  const empresaId = JSON.parse(localStorage.getItem('empresa'))
  const token = empresaId.token;

  const [data, setData] = useState()
  const [empresa] = useState(empresaId._id)
  const [bool, setBool] = useState(true)

  const getEmpresa = async () => {
    setBool(true)
    try {
      const { data } = await Api.get(`/empresas/listempresas/${empresa}`, { "headers": { 'x-access-token': token } });
      data != null ? setData(data) : setData({})
      setBool(false)
    } catch (error) {
      setBool(false)
      console.log("Ocorreu um erro ao buscar a empresa");
    }
  }

  useEffect(() => {
    getEmpresa();
  }, [])

  return (
    <>
      <Header />
      <Container fluid>
        {bool && <Loader />}
        <EmpresaForm data={data} />
      </Container>
    </>
  )
}
export default EditarEmpresa