import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import CardContent from '@mui/material/CardContent';
import Api from '../../../../services/Api';
import Grid from '@mui/material/Grid';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dateFormat from 'dateformat';

const options = {
  responsive: true,
  scales: {
    y: {},
    x: {},
  },
  hoverRadius: 5,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },
  plugins: {
    tooltip: {
      enabled: true
    },
    title: {
      display: false,
      text: '',
    }
  }
}

const plugins = [{
  afterDraw: function (chart) {
    if (chart.data.datasets.length > 0) {
      if (chart.data.datasets[0].data.length === 0) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = "Nenhuma Informação Disponível",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
  }
}]

const Graphics = (props) => {
  const { equipaments } = props;

  const [dataUmidade, setDataUmidade] = useState({
    labels: [],
    datasets: []
  });

  const [dataTemperatura, setDataTemperatura] = useState({
    labels: [],
    datasets: []
  });

  const [dataPH, setDataPH] = useState({
    labels: [],
    datasets: []
  });

  const [length, setLength] = useState(0);

  useEffect(() => {
    const getData = async () => {
      var data = [];
      try {
        if (equipaments && equipaments.length === 10) {
          const response = await Api.post('/measurements/day', { "filter": [equipaments] });
          if (response.data.length) {
            const dataLength = response.data.length;
            setLength(dataLength);
            data = response.data;
          } else {
            console.log(`Nenhum dado para o equipamento ${equipaments}`);
            return;
          }
        }
      } catch (error) {
        if (error.response) {
          console.log("Erro na resposta da API:", error.response.data);
        } else if (error.request) {
          console.log("Erro na requisição:", error.request);
        } else {
          console.log("Erro ao configurar a requisição:", error.message);
        }
      }

      if (data) {
        const labels = data.map(i => dateFormat(i.date, "UTC:HH:MM"))
        setDataUmidade({
          labels,
          datasets: [
            {
              label: 'Umidade'.toUpperCase(),
              data: data.map((i) => (parseFloat(i.humidity).toFixed(1))),
              borderColor: 'rgba(27, 93, 67, 0.8)',
              borderWidth: 2,
              fill: true,
              pointRadius: 2,
              tension: 0.2,
              datalabels: {
                labels: {
                  title: {
                    color: "grey",
                  }
                },
                display: 'auto',
                align: 'end',
                font: {
                  weight: 500,
                  size: 12,
                }
              },
            }
          ],
        });

        setDataTemperatura({
          labels,
          datasets: [
            {
              label: 'Temperatura'.toUpperCase(),
              data: data.map(i => (parseFloat(i.temperature).toFixed(1))),
              borderColor: 'rgba(27, 93, 67, 0.8)',
              borderWidth: 2,
              fill: true,
              pointRadius: 2,
              tension: 0.2,
              datalabels: {
                labels: {
                  title: {
                    color: "grey",
                  }
                },
                display: 'auto',
                align: 'end',
                font: {
                  weight: 500,
                  size: 12,
                }
              },
            }
          ],
        });

        setDataPH({
          labels,
          datasets: [
            {
              label: 'PH'.toUpperCase(),
              data: data.map(i => (parseFloat(i.ph).toFixed(0).replace(/\D/g, ""))),
              borderColor: 'rgba(27, 93, 67, 0.8)',
              borderWidth: 2,
              fill: true,
              pointRadius: 2,
              tension: 0.2,
              datalabels: {
                labels: {
                  title: {
                    color: "grey",
                  }
                },
                display: 'auto',
                align: 'end',
                font: {
                  weight: 500,
                  size: 12,
                }
              },
            }
          ],
        })
      }
    }
    getData();
  }, []);

  return (
    <>
      {dataUmidade.labels.length ?
        <div style={{ "marginTop": 20 }}>
          <div style={{ "textAlign": 'center', "marginBottom": 5 }}>
            <h4 style={{ "color": 'white', "margin": 5 }}> {`Medidas hoje ${length} equipamento ${equipaments}`.toUpperCase()}</h4>
          </div>
          <Grid item>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div style={{ "textAlign": 'center' }}>
                      <Line
                        height={150}
                        options={options}
                        data={dataUmidade}
                        plugins={[plugins, ChartDataLabels]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ "textAlign": 'center' }}>
                      <Line
                        height={150}
                        options={options}
                        data={dataTemperatura}
                        plugins={[plugins, ChartDataLabels]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ "textAlign": 'center' }}>
                      <Line
                        height={150}
                        options={options}
                        data={dataPH}
                        plugins={[plugins, ChartDataLabels]}
                      />
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </div>
        : null}
    </>
  )
}
export default Graphics;
