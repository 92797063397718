import React, { useEffect, useState } from 'react'
import EquipamentosForm from './components/EquipamentosForm';
import Api from '../../services/Api';
import Header from '../../components/Header/Header';
import Aler from '../../components/Aler/Aler';
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import Loader from '../../components/Loader/Loader';
import { Container } from 'react-bootstrap';

const EditarEquipamento = () => {
  const empresaId = JSON.parse(localStorage.getItem('empresa'))
  const token = empresaId.token;

  const [bool, setBool] = useState(true)
  const [instrumentos, setInstrumentos] = useState([])
  const [sha1Curva, setSha1Curva] = useState([])
  const [empresa, setEmpresa] = useState(empresaId._id)

  const handleEquipaments = async () => {
    if (empresa) {
      try {
        const instruments = await Api.get(`/instruments/company/${empresa}`, { "headers": { 'x-access-token': token } })
        setInstrumentos(instruments.data)
        setBool(false)
      } catch (error) {
        setBool(false)
        alert("Ocorreu um erro ao buscar os equipamentos.")
      }
    }
  }

  const handleSha1Curva = async () => {
    try {
      const sha1Curva = await Api.get('/charts');
      setSha1Curva(sha1Curva.data)
    } catch (error) {
      console.log("Ocorreu um erro ao buscar os instrumentos.")
    }
  }

  useEffect(() => {
    handleEquipaments()
    handleSha1Curva()
  }, [])

  return (
    <>
      <Header />
      {bool && <Loader />}
      <Container className='mt-3 mb-3' fluid>
        {instrumentos && instrumentos.length > 0 ? (
          instrumentos.map((item) => (
            <div key={item._id}>
              <EquipamentosForm equipaments={item} sha1Curva={sha1Curva} />
            </div>
          ))
        ) : (
          <div>
            <Aler data='Sem equipamentos cadastrados.' />
            <div className='mt-3'>
              <ButtonBack />
            </div>
          </div>
        )}
      </Container>
    </>
  )
}
export default EditarEquipamento
