import React from 'react';
import Button from 'react-bootstrap/Button';
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { useState } from 'react';

const MedicoesForm = (props) => {
  const { equipament } = props;
  const [instruments] = useState(equipament)

  const handleInstrument = () => {
    localStorage.setItem('instruments', JSON.stringify(instruments))
  }

  return (
    <tbody>
      <tr>
        <td className='col-sm-2 text-center'>{instruments.name ? instruments.name : '---'}</td>
        <td className='col-sm-2 text-center'>{instruments.curva ? instruments.curva : '---'}</td>
        <td className='col-sm-2 text-center'>{instruments.equipamentos.entrada.ns ? instruments.equipamentos.entrada.ns : '---'}</td>
        <td className='col-sm-2 text-center'>{instruments.equipamentos.saida.ns ? instruments.equipamentos.saida.ns : '---'}</td>
        <td className='text-center'>
          <Link to='/medicoes/visualizar'>
            <Button className='col-sm-10' variant="outline-success" size='sm' onClick={() => { handleInstrument() }}> Detalhes <BsArrowRightShort /></Button>
          </Link>
        </td>
      </tr>
    </tbody>
  )
}
export default MedicoesForm;
