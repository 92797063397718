import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Header from '../../components/Header/Header'
import Form from 'react-bootstrap/Form';
import { BsSave2 } from "react-icons/bs";
import ButtonBack from '../../components/ButtonBack/ButtonBack';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Api from '../../services/Api';
import 'react-multi-email/dist/style.css';
import Loader from '../../components/Loader/Loader';

const Notificacoes = () => {
  const [empresa, setEmpresa] = useState(JSON.parse(localStorage.getItem('empresa')))
  const token = empresa.token
  const [bool, setBool] = useState(true)

  const handlePut = async () => {
    try {
      const data = await Api.put(`/empresas/updateempresas`, empresa, { headers: { 'x-access-token': token } })
      localStorage.setItem('empresa', JSON.stringify(data.data))
      if (data.status === 200) {
        alert('Atualizado com sucesso.')
      }
    } catch (err) {
      alert('Erro ao atualizar.')
    }
  }

  useEffect(() => {
    setInterval(() => { setBool(false) }, 500);
  }, [])
  
  return (
    <>
      <Header />
      {bool && <Loader />}
      <Card className='contain mb-3 mt-3 p-3'>
        <Row>
          <Form>
            <hr />
            <h5>Notificações</h5>
            <Form.Check type='checkbox' id='humidity'>
              <Form.Check.Input type='checkbox'
                onChange={(event) => {
                  empresa.notification.changeHumidity = event.target.checked;
                  setEmpresa({ ...empresa })
                }}
                checked={empresa.notification.changeHumidity}
                isValid />
              <Form.Check.Label> Variação de Umidade </Form.Check.Label>
            </Form.Check>

            <Form.Check type='checkbox' id='temperature'>
              <Form.Check.Input type='checkbox'
                onChange={(event) => {
                  empresa.notification.changeTemperature = event.target.checked;
                  setEmpresa({ ...empresa })
                }}
                checked={empresa.notification.changeTemperature}
                isValid />
              <Form.Check.Label> Variação de Temperatura </Form.Check.Label>
            </Form.Check>

            <Form.Check type='checkbox' id='ph'>
              <Form.Check.Input type='checkbox'
                onChange={(event) => {
                  empresa.notification.changePH = event.target.checked;
                  setEmpresa({ ...empresa })
                }}
                checked={empresa.notification.changePH}
                isValid />
              <Form.Check.Label> Variação de PH </Form.Check.Label>
            </Form.Check>

            <Form.Check type='checkbox' id='curve'>
              <Form.Check.Input type='checkbox'
                onChange={(event) => {
                  empresa.notification.changeCurve = event.target.checked;
                  setEmpresa({ ...empresa })
                }}
                checked={empresa.notification.changeCurve}
                isValid />
              <Form.Check.Label> Mudança de curva </Form.Check.Label>
            </Form.Check>

            <Form.Check type='checkbox' id='offon'>
              <Form.Check.Input type='checkbox'
                onChange={(event) => {
                  empresa.notification.status = event.target.checked;
                  setEmpresa({ ...empresa })
                }}
                checked={empresa.notification.status}
                isValid
                disabled
              />
              <Form.Check.Label> Online/Offline </Form.Check.Label>
            </Form.Check>
            <hr />
            <h5 className='mt-3'>Canais de Comunicação</h5>
            <Form.Check type='checkbox' id='mail'>
              <Form.Check.Input type='checkbox'
                onChange={(event) => {
                  empresa.channel.email = event.target.checked;
                  setEmpresa({ ...empresa })
                }}
                checked={empresa.channel.email}
                isValid />
              <Form.Check.Label> E-mail </Form.Check.Label>
            </Form.Check>
            <hr />
            <h5 className='mt-3'> Lista de E-mails </h5>
            <ReactMultiEmail
              className='mb-3'
              placeholder=""
              emails={empresa.notification.notifyTo}
              onChange={(_emails) => {
                empresa.notification.notifyTo = _emails;
                setEmpresa({ ...empresa });
              }}
              validateEmail={email => {
                return isEmail(email); // return boolean
              }}
              getLabel={(
                email,
                index,
                removeEmail,
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />

          </Form>
        </Row>
        <Col>
          <ButtonBack />
          <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => { handlePut() }} >
            <BsSave2 /> Salvar
          </Button>
        </Col>
      </Card>
    </>
  )
}
export default Notificacoes