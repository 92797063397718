import React from 'react';
import Button from 'react-bootstrap/Button';
import { Card, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import { BsGear } from "react-icons/bs";

const Formulario = (props) => {
  const { data } = props;

  return (
    <Card className='p-3 mt-3 mb-3'>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th colSpan={2}>Empresa</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ "background": "rgba(41, 140, 101, 0.6)" }}>
            <td className='col-sm-4'>Nome</td>
            <td>{data ? data.name : "---"}</td>
          </tr>
          <tr>
            <td>E-mail</td>
            <td> {data ? data.email : "---"}</td>
          </tr>
          <tr>
            <td className='col-sm-4'>CNPJ</td>
            <td>{data ? data.cnpj : "---"}</td>
          </tr>
          <tr>
            <td className='col-sm-4'>Endereço</td>
            <td>{data ? data.location.addr : "---"}</td>
          </tr>
          <tr>
            <td className='col-sm-4'>ID da empresa</td>
            <td> <b> {data ? data._id : "---"} </b> </td>
          </tr>
        </tbody>
      </Table>
      <Col>
        <ButtonBack />
        <Link to="/editarempresa/empresa">
          <Button style={{ "marginRight": "5px" }} className='col-sm-2' size='sm' variant="success" >
            <BsGear /> Editar
          </Button>
        </Link>
      </Col>
    </Card>
  )
}
export default Formulario