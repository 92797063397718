import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import Financial from './components/Financial/Financial';
import Graphics from './components/Graphics/Graphics';
import Volume from './components/Volume/Volume';
import Api from '../../services/Api';
import Aler from '../../components/Aler/Aler';
import { Container } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';

const Home = () => {
  const empresa = JSON.parse(localStorage.getItem('empresa'));
  const token = empresa.token;
  const [equipaments, setEquipaments] = useState([]);
  var [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (empresa) {
      (async () => {
        try {
          var data = await Api.get(`/instruments/equipments/${empresa._id}`, { "headers": { 'x-access-token': token } })
          setEquipaments(data.data.filter(item => item.length === 10));
        } catch (err) {
          setEquipaments([])
          alert("Ocorreu um erro ao carregar os equipamentos.")
        }
      })()
    }
  }, [])

  return (
    <>
      <Header />
      <Container className='mt-3 mb-3' fluid>
        {isLoading && <Loader />}
        <>
          <Financial />
          {equipaments.length ?
            <Volume equipaments={equipaments} setIsLoading={setIsLoading} />
            : <Aler data={'Sem equipamentos cadastrados.'} />
          }
          {equipaments.length && equipaments.map(item => (
            <Graphics key={item} equipaments={item} />
          ))}
        </>
      </Container>
    </>
  )
}
export default Home