import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header'
import Api from '../../services/Api';
import MedicoesForm from './components/MedicoesForm';
import BottonBack from '../../components/ButtonBack/ButtonBack'
import Aler from '../../components/Aler/Aler';
import Loader from '../../components/Loader/Loader';
import { Container, Card, Table } from 'react-bootstrap';

const Medicoes = () => {
  const [empresa] = useState(JSON.parse(localStorage.getItem('empresa')))
  const token = empresa.token
  const [instrumentos, setInstrumentos] = useState([])
  const [bool, setBool] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const instruments = await Api.get(`/instruments/company/${empresa._id}`, { "headers": { 'x-access-token': token } })
        if (instruments.status === 200) {
          setInstrumentos(instruments.data)
        }
      } catch (erro) {
        console.error('Ocorreu um erro:', erro);
      }
    })();
    setBool(false)
  }, [])

  return (
    <>
      <Header />
      {bool && <Loader height={80} />}
      <Container className='mt-3 mb-3' fluid>
        <Card>
          {instrumentos.length ?
            <Table striped bordered hover size="sm" className='mb-0'>
              <thead>
                <tr>
                  <th className='col-sm-2 text-center'>Nome</th>
                  <th className='col-sm-2 text-center'>Curva</th>
                  <th className='col-sm-2 text-center'>Entrada</th>
                  <th className='col-sm-2 text-center'>Saída</th>
                  <th className='col-sm-2 text-center'>Detalhes</th>
                </tr>
              </thead>
              {instrumentos &&
                instrumentos.map((item => {
                  return (
                    <MedicoesForm key={item._id} equipament={item} />
                  )
                }))
              }
            </Table>
            : <Aler data={'Sem equipamentos cadastrados.'} />
          }
        </Card>
        <div className='mt-3'>
          <BottonBack />
        </div>
      </Container>
    </>
  )
}
export default Medicoes
