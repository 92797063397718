import React from "react"
import { Link, useNavigate } from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BsFillBarChartFill, BsBriefcaseFill } from "react-icons/bs";
import { HiHome } from "react-icons/hi";
import { AiTwotoneSetting, AiFillBuild } from "react-icons/ai";
import { IoIosExit } from "react-icons/io";

const NavBar = () => {
  const navigate = useNavigate()
  const empresa = JSON.parse(localStorage.getItem('empresa'))
  const expand = false

  const handleLogout = () => {
    localStorage.clear()
    navigate('/')
  }

  return (
    <Navbar bg="light" expand={expand} className="mb-0">
      <Container fluid>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Link style={{ "textDecoration": "none" }} to='/home'>
          <h3 style={{ "color": "#015431", "margin": "0px" }}>{empresa.name}</h3>
        </Link>
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <Link style={{ "textDecoration": "none" }} to='/home'>
                <h3 className="name-company">Motomco</h3>
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Link to="/home" className="nav-bar"><HiHome /> Home </Link>
              <Link to="/medicoes" className="nav-bar"><BsFillBarChartFill /> Medidas </Link>
              <Link to="/editarequipamentos" className="nav-bar"><AiFillBuild /> Equipamentos </Link>
              <Link to="/notificacoes" className="nav-bar"><AiTwotoneSetting /> Notificacões </Link>
              <Link to="/editarempresa" className="nav-bar"><BsBriefcaseFill /> Empresa </Link>
              {/* <Link to="https://monitoradash.motomco.com.br/" target="_blank" className="nav-bar"><AiFillDashboard /> Dashboard</Link> */}
              <Link to="/" onClick={() => {
                handleLogout()
              }} className="nav-bar"><IoIosExit /> Sair </Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
export default NavBar