import React, { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import Api from "../../services/Api";

const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    if (!email | !senha) {
      setError('Preencha todos os campos.');
      return;
    }

    try {
      const empresa = await Api.post(`/empresa/login`, {
        "email": email,
        "password": senha
      })
      if (empresa) {
        empresa.data.date = Date().toString()
        localStorage.setItem('empresa', JSON.stringify(empresa.data))
        return navigate("/home");
      }
    } catch (err) {
      setError('E-mail ou senha inválidos.')
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin()
    }
  }

  return (
    <C.ContainerSignin>
      <C.Container>
        <C.Content>
          <C.Label>MOTOMCO Monitora</C.Label>
          <Input
            type="email"
            placeholder="Digite seu E-mail"
            value={email}
            onChange={(e) => [setEmail(e.target.value), setError('')]}
          />
          <Input
            type="password"
            placeholder="Digite sua Senha"
            value={senha}
            onKeyPress={handleKeyPress}
            onChange={(e) => [setSenha(e.target.value), setError('')]}
          />
          <C.labelError>{error}</C.labelError>
          <Button Text="Entrar" onClick={() => { handleLogin() }} />
        </C.Content>
      </C.Container>
    </C.ContainerSignin>
  );
};
export default Signin;