import React from 'react'
import Button from 'react-bootstrap/Button';
import { BsArrowLeftShort } from "react-icons/bs";

const ButtonBack = (props) => {

    return (
        <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="primary" size='sm' onClick={() => { window.history.back() }} >
            <BsArrowLeftShort /> Voltar
        </Button>
    )
}
export default ButtonBack
