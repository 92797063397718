import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Button from 'react-bootstrap/Button'
import Api from '../../../../services/Api'
import cima from '../../../../../src/img/icons/keyboard-arrow-down.ico'
import baixo from '../../../../../src/img/icons/keyboard-arrow-up.ico'

const Financial = () => {
  const maxFinancialHeight = '100%';
  const minFinancialHeight = '20vh';
  const [financialHeight, setFinancialHeight] = useState(minFinancialHeight);
  const [widgetMilho, setWidgetMilho] = useState('');
  const [widgetSoja, setWidgetSoja] = useState('');
  const [widgetTrigo, setWidgetTrigo] = useState('');
  const [dolar, setDolar] = useState(null);

  const handleVisualizeFinancial = () => {
    if (financialHeight === minFinancialHeight) {
      setFinancialHeight(maxFinancialHeight);
    } else {
      setFinancialHeight(minFinancialHeight);
    }
  }

  const handleFinancial = async () => {
    try {
      const { data } = await Api.get('/financial/130');
      setWidgetMilho(data);
    } catch (err) {
      return
    }

    try {
      const { data } = await Api.get('/financial/127');
      setWidgetSoja(data);
    } catch (err) {
      return
    }

    try {
      const { data } = await Api.get('/financial/225');
      setWidgetTrigo(data);
    } catch (err) {
      return
    }

    try {
      const { data } = await Api.get('/financial/cotacao/dolar');
      setDolar(data.USDBRL);
    } catch (err) {
      return
    }
  }

  useEffect(() => {
    handleFinancial()
  }, [])

  return (
    <Grid className='' container spacing={4}>
      <Grid item xs={12}>
        <div style={{ "textAlign": 'center', "margin": "5" }}>
          <h4 style={{ "color": 'white', "margin": 5 }}>{'Mercado financeiro'.toUpperCase()}</h4>
        </div>
        <Grid>
          <Card>
            <CardContent>
              {dolar ?
                <div style={{ "textAlign": 'center', "border": '1px solid black', "borderRadius": "8px", "marginBottom": '8px' }}>
                  <h5>Cotação Dolar BRL</h5>
                  <h3 style={{ "marginBottom": "0px" }}>{dolar.ask}</h3>
                </div>
                : null}
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ "textAlign": 'center' }}>
                    <div dangerouslySetInnerHTML={{ __html: widgetMilho }} style={{ height: financialHeight }} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ "textAlign": 'center' }}>
                    <div dangerouslySetInnerHTML={{ __html: widgetSoja }} style={{ height: financialHeight }} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ "textAlign": 'center' }}>
                    <div dangerouslySetInnerHTML={{ __html: widgetTrigo }} style={{ height: financialHeight }} />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <div style={{ "textAlign": 'center' }}>
          <Button
            style={{ "width": '100%', "background": 'white', "border": '0px solid black', "marginTop": "3px" }}
            className="button-financial"
            onClick={handleVisualizeFinancial}
            variant="outlined">
            {financialHeight === minFinancialHeight ? <img src={cima} width='25' alt="" /> : <img src={baixo} width='25' alt="" />}
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
export default Financial;
