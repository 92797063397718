import React from "react"
import { Alert } from "react-bootstrap"

const Aler = (props) => {
  const { data } = props
  return (
    <Alert className="mt-3" variant='danger' style={{"textAlign": "center"}}>
      {data}
    </Alert>
  )
}
export default Aler