import { Container } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = () => {
  return (
    <Container className="loader" fluid>
      <ClipLoader color={"grey"} size={130} loading={true} />
    </Container>
  )
}
export default Loader;