import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import useAuth from './hooks/useAuth'
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home'
import Signin from './pages/Signin/Signin'
import EditarEmpresa from './pages/EditarEmpresa/EditarEmpresa';
import EditarEquipamento from './pages/EditarEquipamento/EditarEquipamento'
import EditFormulario from './pages/EditarEquipamento/components/components/EditFormulario';
import { createBrowserHistory } from 'history';
import Medicoes from './pages/Medicoes/Medicoes';
import EditEmpresa from './pages/EditarEmpresa/components/components/EditEmpresa';
import MedicoesVisualizar from './pages/Medicoes/components/components/MedicoesVisualizar';
import Notificacoes from './pages/Notificacoes/Notificacoes';
import Dashboard from './pages/Dashboard/Dashboard';
import { checkAuth } from './hooks/actions/sessionActions';

const history = createBrowserHistory();

const App = () => {
  const Private = ({ Item }) => {
    const signed = useAuth();
    const session = checkAuth();
    return !signed && session ? <Item /> : <Signin />;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <Routes history={history}>
            <Route exact path="/home" element={<Private Item={Home} />} />
            <Route exact path="/medicoes" element={<Private Item={Medicoes} />} />
            <Route exact path="/editarequipamentos" element={<Private Item={EditarEquipamento} />} />
            <Route exact path="/editarequipamentos/instruments" element={<Private Item={EditFormulario} />} />
            <Route exact path="/notificacoes" element={<Private Item={Notificacoes} />} />
            <Route exact path="/editarempresa" element={<Private Item={EditarEmpresa} />} />
            <Route exact path="/editarempresa/empresa" element={<Private Item={EditEmpresa} />} />
            <Route exact path="/medicoes/visualizar" element={<Private Item={MedicoesVisualizar} />} />
            <Route exact path="/dashboard" element={<Private Item={Dashboard} />} />
            <Route exact path='/' element={<Signin />} />
            <Route path="*" element={<Signin />} />
          </Routes>
          <Footer />
        </Fragment>
      </BrowserRouter>
    </div>
  )
}
export default App;
