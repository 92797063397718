import React from "react";
import Header from "../../components/Header/Header";

const Dashboard = () => {

  return (
    <>
      <Header />
      <div className="" style={{ "height": "100vh" }}>
        <iframe src="http://ec2-54-232-64-75.sa-east-1.compute.amazonaws.com:4297/dashboard" width="100%" height="100%"></iframe>
      </div>
    </>
  )
}
export default Dashboard
