import React from 'react';
import Button from 'react-bootstrap/Button';
import { Card, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import cima from '../../../../src/img/icons/cima.svg'
import baixo from '../../../../src/img/icons/baixo.svg'
import ButtonBack from '../../../components/ButtonBack/ButtonBack';
import { BsGear } from "react-icons/bs";

const EquipamentosForm = (props) => {
  const { equipaments, sha1Curva } = props;

  const handleInstrument = () => {
    localStorage.setItem('instruments', JSON.stringify(equipaments))
    localStorage.setItem('sha1Curva', JSON.stringify(sha1Curva))
    localStorage.setItem('curva', JSON.stringify(equipaments.curva))
  }

  return (
    <Card className='p-3 mb-3'>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th className='h5' >{equipaments.name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='col-sm-2'>Sha1Curva:</td>
            <td>{equipaments.sha1Curva}</td>
          </tr>

          <tr>
            <td className='col-sm-2'>Curva:</td>
            <td>{equipaments.curva}</td>
          </tr>

          <tr style={{ "background": "rgba(41, 140, 101, 0.6)" }}>
            <td className='col-sm-2'>NS instrumento de entrada:</td>
            <td className='col-sm-2'>{equipaments.equipamentos.entrada.ns}</td>
            <td className='col-sm-2'>NS instrumento de saída:</td>
            <td className='col-sm-2'>{equipaments.equipamentos.saida.ns}</td>
          </tr>
          <tr>
            <td className='col-sm-2'>Max umidade entrada:</td>
            <td> <img src={cima} width="7px" alt="" /> {equipaments.equipamentos.entrada.configs.maxHumidity}%</td>
            <td>Max umidade saída:</td>
            <td><img src={cima} width="7px" alt="" /> {equipaments.equipamentos.saida.configs.maxHumidity}%</td>
          </tr>

          <tr>
            <td className='col-sm-2'>Min umidade entrada:</td>
            <td><img src={baixo} width="7px" alt="" /> {equipaments.equipamentos.entrada.configs.minHumidity}%</td>
            <td>Min umidade saída:</td>
            <td><img src={baixo} width="7px" alt="" /> {equipaments.equipamentos.saida.configs.minHumidity}%</td>
          </tr>

          <tr>
            <td className='col-sm-2'>Max temperatura entrada:</td>
            <td> <img src={cima} width="7px" alt="" /> {equipaments.equipamentos.entrada.configs.maxTemperature}°</td>
            <td>Max temperatura saída:</td>
            <td><img src={cima} width="7px" alt="" /> {equipaments.equipamentos.saida.configs.maxTemperature}°</td>
          </tr>

          <tr>
            <td className='col-sm-2'>Min temperatura entrada:</td>
            <td><img src={baixo} width="7px" alt="" /> {equipaments.equipamentos.entrada.configs.minTemperature}°</td>
            <td>Min temperatura saída:</td>
            <td><img src={baixo} width="7px" alt="" /> {equipaments.equipamentos.saida.configs.minTemperature}°</td>
          </tr>

          <tr>
            <td className='col-sm-2'>Max PH entrada:</td>
            <td> <img src={cima} width="7px" alt="" /> {equipaments.equipamentos.entrada.configs.maxPH}</td>
            <td>Max PH saída:</td>
            <td><img src={cima} width="7px" alt="" /> {equipaments.equipamentos.saida.configs.maxPH}</td>
          </tr>

          <tr>
            <td className='col-sm-2'>Min PH entrada:</td>
            <td><img src={baixo} width="7px" alt="" /> {equipaments.equipamentos.entrada.configs.minPH}</td>
            <td>Min PH saída:</td>
            <td><img src={baixo} width="7px" alt="" /> {equipaments.equipamentos.saida.configs.minPH}</td>
          </tr>
        </tbody>
      </Table>
      <Col>
        <ButtonBack />
        <Link to="/editarequipamentos/instruments">
          <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={handleInstrument} >
            <BsGear /> Editar
          </Button>
        </Link>
      </Col>
    </Card>
  )
}
export default EquipamentosForm