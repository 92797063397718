import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Header from '../../../../components/Header/Header';
import Button from 'react-bootstrap/Button';
import Api from '../../../../services/Api';
import ButtonBack from '../../../../components/ButtonBack/ButtonBack';
import { BsSave2, BsGear } from "react-icons/bs";

const EditEmpresa = (props) => {
  const navigate = useNavigate()
  const [empresa, setEmpresa] = useState(JSON.parse(localStorage.getItem('empresa')))
  const token = empresa.token;

  const [pass1, setPass1] = useState('')
  const [pass2, setPass2] = useState('')
  const [verifySenha, setVerifySenha] = useState(false)
  const [bool, setBool] = useState(true)

  const handleVerifySenha = () => {
    setVerifySenha(true)
  }

  const handleValidateSave = () => {
    if (pass1 === '' && pass2 === '') {
      setEmpresa(empresa)
      handlePut()
    } else {
      handleSave()
    }
  }

  const handleSave = () => {
    if (pass1.length > 4 && pass2.length > 4) {
      if (pass1 === pass2) {
        empresa.password = pass1
        setEmpresa({ ...empresa })
        handlePut()
      } else {
        alert('A senha está diferente.')
      }
    } else {
      alert('Digite uma senha maior.')
    }
  }

  const handlePut = async () => {
    try {
      const data = await Api.put(`/empresas/updateempresas`, empresa, { headers: { 'x-access-token': token } })
      localStorage.setItem('empresa', JSON.stringify(data.data))
      if (data.status === 200) {
        navigate("/editarempresa")
      }
    } catch (err) {
      alert('Erro ao atualizar.')
    }
  }

  useEffect(() => {
    setInterval(() => { setBool(false) }, 1000);
  }, [])

  return (
    <>
      <Header />
      {!bool ?
        <Card className='contain p-3 mt-3 mb-3'>
          <Row className='p-2'>
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-6"
              autoComplete="off"
              id="outlined-basic1"
              label="Nome"
              variant="outlined"
              type="text"
              onChange={(event) => {
                empresa.name = event.target.value;
                setEmpresa({ ...empresa });
              }}
              value={empresa.name}
              defaultValue="Small"
              size="small"
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-6"
              autoComplete="off"
              id="outlined-basic2"
              label="E-mail"
              variant="outlined"
              type="email"
              onChange={(event) => {
                empresa.email = event.target.value;
                setEmpresa({ ...empresa });
              }}
              value={empresa.email}
              defaultValue="Small"
              size="small"
              disabled
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-6"
              autoComplete="off"
              id="outlined-basic3"
              label="CNPJ"
              variant="outlined"
              type="text"
              value={empresa.cnpj}
              defaultValue="Small"
              size="small"
              disabled
            />
            <TextField
              style={{ "paddingRight": "5px" }}
              className="mb-3 mt-2 col-sm-6"
              autoComplete="off"
              id="outlined-basic4"
              label="Endereço"
              variant="outlined"
              type="text"
              value={empresa.location.addr}
              defaultValue="Small"
              size="small"
              disabled
            />
            {verifySenha === true ?
              <>
                <TextField
                  style={{ "paddingRight": "5px" }}
                  className="mb-3 mt-2 col-sm-12"
                  autoComplete="off"
                  id="outlined-basic5"
                  label="Nova senha"
                  variant="outlined"
                  type="password"
                  onChange={(event) => {
                    let password1 = event.target.value;
                    setPass1(password1);
                  }}
                  value={pass1}
                  defaultValue="Small"
                  size="small"
                />
                <TextField
                  style={{ "paddingRight": "5px" }}
                  className="mb-3 mt-2 col-sm-12"
                  autoComplete="off"
                  id="outlined-basic6"
                  label="Confirmar senha"
                  variant="outlined"
                  type="password"
                  onChange={(event) => {
                    let password2 = event.target.value;
                    setPass2(password2);
                  }}
                  value={pass2}
                  defaultValue="Small"
                  size="small"
                />
              </>
              : null
            }
          </Row>
          <Col>
            <ButtonBack />
            <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="success" size='sm' onClick={() => { handleValidateSave() }}>
              <BsSave2 /> Salvar
            </Button>

            {!verifySenha ?
              <Button style={{ "marginRight": "5px" }} className='col-sm-2' variant="secondary" size='sm' onClick={() => {
                handleVerifySenha()
              }} >
                <BsGear /> Editar senha
              </Button>
              : null
            }
          </Col>
        </Card>
        : <div>teste</div>
      }
    </>
  )
}
export default EditEmpresa
